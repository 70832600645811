:root {
  --onlyfans-color: #ea0aaa;
  --font-family: "Poppins", Sans-serif;
}

@font-face {
  font-family: Comfortaa-Bold;
  src: url(../src/assets/fonts/Comfortaa-Bold.ttf);
}

body {
  background-color: #edf0f7;
}

.App {
  overflow-x: hidden;
}

.header {
  background: url("./assets/images/banner.png") no-repeat;
  background-size: 100% auto;
  background-position: 50% 0%;
  aspect-ratio: 2/1;
  max-height: 70dvh;
  width: 100dvw;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 30px;
  max-width: 2140px;
  padding: 20px 60px 0px 60px;
  margin: 0 auto;
}

.spacer {
  width: 33%;
}

.name {
  background-image: linear-gradient(to right, #FFFFFF 0%, var(--onlyfans-color) 100%);
  background-clip: text;
  color: transparent;
  font-family: Comfortaa-Bold;
  font-size: 1.75rem;
  font-weight: 900;
  display: inline-block;
  width: 33%;
  text-align: center;
}

.header_button{
  display: flex;
  justify-content: end;
  width: 33%;
}

.onlyfans_button {
  border: 1.5px solid white;
  padding: 12px 35px;
  border-radius: 1.5em;
  color:#FFFFFF;
  background-color: var(--onlyfans-color);
  transition: all 0.2s;
  cursor: pointer;
  font-family: 'Poppins',sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
}

.onlyfans_button:nth-child(1):hover {
    background-color: #afcfdf;
    border-color: var(--onlyfans-color);
}

.onlyfans_button:nth-child(2):hover {
  transform: scale(1.1);
}

.onlyfans_button:nth-child(3):hover {
  transform: scale(1.1);
}


.center {
  position: absolute;
  background-color: black;
  left: 50%;
  transform: translateX(-50%);
  width: 3px;
  height: 500px;
}

.title {
  max-width: 2140px;
  height: 90%;
  padding: 20px 60px 0px 60px;
  margin: 10% auto 25px auto;
}

.reviews_container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
}

.review {
  margin-top: 50px;
  flex: 0 0 auto; /* Ensure each review does not grow or shrink */
  margin-right: 20px; /* Add some space between reviews */
  max-width: 250px; /* Set maximum width */
}

.review img {
  width: 100%; /* Ensure the image fills the container */
  height: auto; /* Maintain aspect ratio */
}

.review:last-child {
  margin-right: 0; /* Remove margin for the last review */
}

@media (min-width: 768px) {
  .review {
    width: 45%; /* Each review takes 45% of the width on larger screens */
    margin-right: 5%;
  }

  .review:last-child {
    margin-right: 0; /* Remove margin for the last review */
  }

  .reviews_container {
    display: none; /* Hide reviews container on smaller screens */
  }
}



@media (min-width: 1024px) {
  .review {
    width: 30%; /* Each review takes 30% of the width on larger screens */
    margin-right: 3.3333%;
  }

  .review:last-child {
    margin-right: 0; /* Remove margin for the last review */
  }
}


.status {
  background-color: white;
  color: var(--onlyfans-color);
  width: fit-content;
  padding: 2px 20px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
  font-family: var(--font-family);
}

.main_text {
  color: white;
  font-family: var(--font-family);
  font-size: 74px;
  font-weight: 700;
  line-height: 1.1;
}

.main_text_color{
  color: var(--onlyfans-color);
}

.join {
  border: none;
  margin-top: 35px;
  padding: 13px 50px;
  border-radius: 5rem;
  font-size: 1.05rem;
}

.percs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  max-width: 1240px;
  padding: 20px 60px 0px 60px;
  margin: 5% auto 25px auto;
}

.perc_item {
  width: 25%;
  height: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.perc_image {
  width: 150px;
  background: linear-gradient(90deg, rgba(227,226,236,1) 0%, #ea0aaa 35%, #d21200 100%);
  border-radius: 50%;
  display: inline-block;
  border: 5px;
  position: relative;
}

.perc_image::after {
  content: "";
  width: calc(100% - 8px);
  aspect-ratio: 1 / 1;
  margin-left: 4px;
  margin-top: 4px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
  position: relative;
  background-color: #bbb;
}

.perc_item:nth-child(1) > .perc_image::after{
  background: url("./assets/images/1c.png") no-repeat;
  background-size: 100%;
}

.perc_item:nth-child(2) > .perc_image::after{
  background: url("./assets/images/2c.png") no-repeat;
  background-size: 100%;
}

.perc_item:nth-child(3) > .perc_image::after{
  background: url("./assets/images/3c.png") no-repeat;
  background-size: 100%;
}

.perc_item:nth-child(4) > .perc_image::after{
  background: url("./assets/images/4c.png") no-repeat;
  background-size: 100%;
}

.perc_text {
  padding-left: 25px;
  width: 100px;
  line-height: 1.5;
}

.gallery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;

  min-height: 30px;
  max-width: 1500px;
  padding-top: 20px;
  margin: 0 auto;
  margin-top: 200px;
}

.gallery_text {
  color: var(#000f2b);
  font-family: var(--font-family);
  font-size: 55px;
  font-weight: 700;
  line-height: 1.1;
}

.gallery_text_color{
  color: var(--onlyfans-color);
  text-align: center;
}

.gallery_images{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -30px;
  width: 100%;
}

.float_container {
  width: calc(100% / 5.5);
  height: 450px;
}

.gallery_image {
  box-shadow: 0 0 13px 0px #ea0aaa;
  width: 100%;
  border-radius: 20px;
  flex-shrink: 0;
}

.float_container:nth-child(1) > .gallery_image{
  background: url("./assets/images/1.jpg") no-repeat;
  background-size: 110% auto;
  aspect-ratio: 0.8 / 1;
}

.float_container:nth-child(2) > .gallery_image{
  border-radius: 0px;
  margin-top: 100px;
  background: url("./assets/images/2.jpg") no-repeat;
  background-size: 120%;
  aspect-ratio: 0.68 / 1;
}

.float_container:nth-child(3) > .gallery_image{
  margin-top: 120px;
  background: url("./assets/images/3.jpg") no-repeat;
  background-size: 100%;
  background-position-y: 35%;
  aspect-ratio: 0.83 / 1;
}

.float_container:nth-child(4) > .gallery_image{
  border-radius: 0px;
  margin-top: 100px;
  background: url("./assets/images/4.jpg") no-repeat;
  background-size: 150%;
  background-position: 10%;
  aspect-ratio: 0.74 / 1;
}

.float_container:nth-child(5) > .gallery_image{
  background: url("./assets/images/5.jpg") no-repeat;
  background-size: 155%;
  background-position: 70%;
  aspect-ratio: 0.51 / 1;
}

.end {
  height: 9999px;
}

.flip_images{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  max-width: 1240px;
  padding: 20px 80px 0px 80px;
  margin: 0 auto;
  margin-top: 250px;
}

.flip_container{
  width: calc(50% - 20px);
}

.flip_card {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  transition: transform 1s ease-out;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip_container:nth-child(1) > .flip_card{
  margin-top: 50px;
}

.flip_container:hover .flip_card {
  transform: rotateY(180deg);
}

.flip_card_front, .flip_card_back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip_card_front {
  background-color: #bbb;
  color: black;
}

.card_left > .flip_card_front{
  background: url("./assets/images/1b.jpg") no-repeat;
  background-size: 100%;
  
}

.card_left > .flip_card_back{
  background: url("./assets/images/1f.jpg") no-repeat;
  background-size: 100%;
}

.flip_card_back {
  background-color: #ea0aaa;
  color: white;
  transform: rotateY(180deg);
}

.card_right > .flip_card_front{
  background: url("./assets/images/2b.jpg") no-repeat;
  background-size: 100%;
  background-position-y: -83px;
}

.card_right > .flip_card_back{
  background: url("./assets/images/2f.jpg") no-repeat;
  background-size: 100%;
  background-position-y: -83px;
}

.flip_div {
  width: 65%;
  height: 160px;
  background-color: #edf0f7;
  margin: 0 auto;
  margin-top: -80px;
  position: relative;
  z-index: 20;
  padding: 30px;
}

.flip_title {
  font-size: 28px;
  color: var(--onlyfans-color);
  font-family: var(--font-family);
  line-height: 36px;
  margin-bottom: 25px;
}

.flip_text {
  font-family: var(--font-family);
  font-size: 100%;
  margin-bottom: 25px;
}

.reviews {
  margin-top: 500px;
  max-width: 2140px;
  padding: 20px 60px 0px 60px;
  margin: 0 auto;
  margin-top: 350px;
}

.reviews_container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
  gap: 20px; /* Set the gap between reviews */
}

.review {
  flex: 0 0 auto; /* Ensure each review does not grow or shrink */
  max-width: 250px; /* Set maximum width */
}

.review_video {
  display: none;
}

.stars {
  background: url("./assets/images/5_stars.svg.webp") no-repeat;
  margin: 0% auto;
  width: 130px;
  height: 25px;
  background-size: 100%;
}

.comment {
  padding: 20px 10px 20px 20px;
  font-size: 19px;
}

.action {
  margin-top: 300px;
  width: 100%;
  position: relative;
}

.waves {
  position: absolute;
  width: 100%;
}

.wave {
  color: var(--onlyfans-color);
  width: 100%;
  aspect-ratio: 3 / 1;
  max-height: 450px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  color: white;
}

.wave1 {
  transform: rotate(180deg);
  position: relative;
  top: 10px;
}

.action_container{
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 30px;
  max-width: 1900px;
  padding: 20px 60px 0px 60px;
  margin: 0 auto;
}

.action_box {
  background-color: #edf0f7;
  width: 80%;
  aspect-ratio: 2 / 1;
  position: relative;
  z-index: 10;
  gap: 20px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 35px;
  margin: 0% auto;
}

.action_square {
  width: 50%;
  height: 100%;
  background-color: #d0d0d0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.action_image {
  background: url("./assets/images/phone_pics.png") no-repeat;
  margin: 0% auto;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-color: #eff0f4;
}

.action_text {
  width: 50%;
  height: 100%;
}

.action_title {
  font-size: 2.7rem;
}

.action_text_color {
  color: var(--onlyfans-color);
  font-size: 2.5rem;
  margin-bottom: 15px;
}


.footer {
  margin-top: 200px;
  width: 100%;
  height: 100px;
  background-color: black;
  padding-top: 50px ;
}


@media only screen and (min-width: 1890px) {
  .name { font-size: 3rem;}
  .onlyfans_button { font-size: 1.5rem;}

  .title { margin-top: 13%; }
  .status { font-size: 20px; }
  .main_text { font-size: 140px; }
  .join { font-size: 1.5rem; }
 
  .header_transition { top: -200px; }
  .header_path { max-height: 250px; width: 300%;}

  .gallery { margin-top: 100px; }
  .flip_images { margin-top: 350px; }

  .stars { width: 180px; height: 50px;}
  .comment { font-size: 29px;}
}

@media only screen and (max-width: 830px) {
  .onlyfans_button { font-size: 0.8rem; padding: 6px 25px;}
  .title { font-size: 3vw;}
  .main_text { font-size: 10vw; }
  .header_transition { top: -160px; }
  .header_path { width: 600%;}

  .percs {
    flex-wrap: wrap;
    padding: 2%;
    width: 98%;
    height: 300px;
    gap: 60px 20px;
  }

  .perc_item { flex: 40%; }

  .gallery {margin-top: 100px;}
  .gallery_text {margin-bottom: 80px; font-size: 40px;}
  .flip_images { margin-top: 100px; }

  .review_text {font-size: 50px; margin-bottom: 0px;}
  .stars { width: 100px; height: 25px;}
}


@media only screen and (max-width: 720px) {
  .row {justify-content: start; padding: 20px 20px;}
  .spacer {width: 0%;}
  .name { width: 80%; text-align: left;}

  .title { margin-top: 1%; padding: 10px 20px;}
  .status {font-size: 10px;}

  .header_transition { display: none;}

  .percs {
    row-gap: 1px;
  }
  .perc_image { width: 100px;}

  .flip_images { margin-top: 0px; padding: 10px; }
  .flip_div { padding: 8px; margin-top: -35px;}
  .flip_title { font-size: medium; line-height: normal; text-align: center; margin-bottom: 10px;}
  .flip_text { font-size: small; margin-bottom: 10px;}
  .card_right > .flip_card_front{ background-position-y: -53px; }

  .review_text { font-size: 30px; line-height: normal;}
  .review_video {  
    display: inline; 
    width: 50%; 
    margin: 0px auto; 
    position: relative; 
    left: 50%; 
    transform: translateX(-50%); 
    margin-top: 80px;
  }
  .reviews { padding: 10px;}
  .review { width: 100%;}
  .stars { width: 100px; height: 20px;}
  .comment { font-size: 19px; padding: 30px; text-align: center;}

  .action { margin-top: 100px;}
  .action_title, .action_text_color { font-size: 20px; margin-bottom: 0px}
}

@media only screen and (max-width: 445px) {

  .name { font-size: 20px;}
  .onlyfans_button { font-size: 10px;}

  .main_text { font-size: 7vw; }
  .header_transition { display: none;}
  .onlyfans_button { margin-top: 8px;}

  .gallery_text { font-size: 30px;}
  .float_container { width: 80%; margin: 0px auto; height: fit-content;}
  .gallery_image { margin: 20px 0px !important;}

  .action_container { padding: 5px;}
  .action_square { padding: 10px;}
  .action_title, .action_text_color { font-size: 15px;}

  .flip_images { flex-wrap: wrap; margin-top: 180px;}
  .flip_container { width: 90%; margin: 0px auto;  }
}